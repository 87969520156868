import React, {useEffect} from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"

import { Newnavbar } from "../components"

import styles from "./404-css-module.module.scss"
import arrow from "../../static/arrow_navigation.svg"
import bg_mobile_404 from "../assets/404/404-bg-mob.svg"

const NotFoundPage = () => {


  const click = e => {
    e.preventDefault()
    navigate('/')
  }

  useEffect(() => {
      navigate("/404")
  })

  return (
    <>
      <Newnavbar url="/404" hideNav={true} />
      <div className={styles.container}>
        <div className={styles.title_section}>
          <div>
            <span className={styles.title}>
              You can’t find something if it doesn’t exist
            </span>
          </div>
          <div>
            <span className={styles.subTitle}>We Apologise!</span>
          </div>
          <div className={styles.hide__Desktop}>
            <img src={bg_mobile_404} alt="background mobile 404" />
          </div>
        </div>
        <div className={styles.intrestOffer}>
          <div className={styles.width_20}>
            <div>
              <span className={styles.offer_text}>
                Here are some pages that may interest you
              </span>
            </div>
            <div className={styles.border_div}></div>
          </div>
          <div className={styles.arrow_section}>
            <img className={styles.arrow_image} src={arrow} alt="arrow" />
          </div>
          <div className={styles.link_section}>
            <div className="solv-flex-column link-coloumn">
              <ul className={styles.list_without_bullet}>
                <li>
                  <Link to="/about-us/">About Us</Link>
                </li>
                <li>
                  <Link to="/ecommerce/">E-Commerce</Link>
                </li>
                <li>
                  <Link to="/services/financial-services">Finance</Link>
                </li>
                <li>
                  <Link to="/services/business-services">Services</Link>
                </li>
                <li>
                  <Link to="/careers/">Careers</Link>
                </li>
                <li>
                  <Link to="/media/press">Press</Link>
                </li>
                {/* <li>
              <Link to="/">
                  Blog
              </Link>
            </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.navigateBack}>
          <div className={styles.take_back_text_div}>
            <span className={styles.offer_text}>Or we can take you back</span>
          </div>
          <Link to="/" className={styles.take_me_back_div}>
          {/* onClick={click}> */}
            <span >Take me Home &nbsp; &#8594; </span>
          </Link>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
